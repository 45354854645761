import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="image-form-input"
export default class extends Controller {
  static targets = ['image', 'imagePlaceholder', 'fileField', 'removeImageInput', 'removeImageLink'];

  connect() {
    if (this.imageTarget.classList.contains('hidden')) {
      this.imagePlaceholderTarget.classList.remove('hidden');
    }
  }

  changeImage(e) {
    e.preventDefault();

    this.fileFieldTarget.click();
  }

  imageUpdated(e) {
    const [file] = e.target.files;

    if (file) {
      this.imageTarget.classList.remove('hidden');
      this.imagePlaceholderTarget.classList.add('hidden');
      this.imageTarget.src = URL.createObjectURL(file)
      this.removeImageInputTarget.value = 'false';
      this.removeImageLinkTarget.classList.remove('hidden');
    }
  }

  removeImage(e) {
    e.preventDefault();

    this.imageTarget.classList.add('hidden');
    this.imagePlaceholderTarget.classList.remove('hidden');
    this.fileFieldTarget.value = '';
    this.removeImageInputTarget.value = 'true';
    this.removeImageLinkTarget.classList.add('hidden');
  }
}
